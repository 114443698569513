.scrollup {
    position: fixed;
    right: 2rem;
    bottom: -20%;
    background-color: #83B2FF;
    opacity: 0.8;
    padding: .3rem .5rem;
    border-radius: .4rem;
    z-index: var(--z-tooltip);
    transition: .4s;
}

.scrollup-icon {
    font-size: 1.5rem;
    color: var(--container-color);
}

.scrollup:hover {
    background-color: var(--title-color-dark);
}

.show-scroll {
    bottom: 3rem;
}

/* ====== BREAKPOINT ====== */
/* For large devices */
@media screen and (max-width: 992px) {
    .scrollup {
        right: 1.5rem;
        padding: 0.25rem 0.4rem;
    }

    .scroll__icon {
        font-size: 1.25rem;

    }
  }
  
  /* For medium devices */
  @media screen and (max-width: 768px) {
    .show-scroll {
        bottom: 4rem;
    }
  }
  
  /* For small devices */
  @media screen and (max-width: 350px) {
  
    .scrollup {
        right: 1rem;
    }
  
  }