@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

html {
  scroll-behavior: smooth;
}

/* ------ VARIABLES CSS ------ */
:root {
  --header-height: 3rem;

  /* ----- Colors ----- */
  /* Color mode HSL(hue, saturarion, lightness) */
  --hue: 0;
  --sat: 0%;
  --title-color: hsl(var(--hue), var(--sat), 20%);
  --title-color-dark: hsl(var(--hue), var(--sat), 0%);
  --text-color: hsl(var(--hue), var(--sat), 46%);
  --body-color: hsl(var(--hue), var(--sat), 98%);
  --container-color: #fff;

  /* ====== Fonts and typrography ====== */
  --body-font: "Poppins", sans-serif;

  /* ------ .5rem = 8px | 1rem = 16px ------ */
  --big-font-size: 3.5rem;
  --h1-font-size: 2.25rem;
  --h2-font-size: 1.5rem;
  --h3-font-size: 1.25rem;
  --normal-font-size: 1rem;
  --small-font-size: 0.875rem;
  --smaller-font-size: 0.813rem;
  --tiny-font-size: 0.750rem;
  
  /* ------ Font Weight ------ */
  --font-normal: 400;
  --font-medium: 500;
  --font-semi-bold: 600;

  /* ====== Margenes Botton ====== */
  --mb-0-25: 0.25rem;
  --mb-0-5: 0.5rem;
  --mb-0-75: 0.75rem;
  --mb-1: 1rem;
  --mb-1-5: 1.5rem;
  --mb-2: 2rem;
  --mb-2-5: 2.5rem;
  --mb-3: 3rem;

  /* ====== z-index ====== */
  --z-tooltip: 10;
  --z-fixed: 100;
  --z-modal: 1000;

}

/* Responsive typography*/
@media screen and (max-width: 992px) {
  :root {
    --big-font-size: 2.5rem;
    --h1-font-size: 1.5rem;
    --h2-font-size: 1.25rem;
    --h3-font-size: 1rem;
    --normal-font-size: 0.938rem;
    --small-font-size: 0.813rem;
    --smaller-font-size: 0.75rem;
  }
}

/* ====== BASE ====== */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}



html {
  scroll-behavior: smooth;
}

body,
button,
input,
textarea {
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
}

body {
  background-color: var(--body-color);
  color: var(--text-color);
}

h1, h2, h3 {
  color: var(--title-color);
  font-weight: var(--font-semi-bold);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
  border: none;
  outline: none;
}

img {
  max-width: 100%;
  height: 100%;
}

/* ====== REUSABLE CSS CLASSES ====== */

.section {
  padding: 4.7rem 0 2rem;
}

.section__title {
  font-size: var(--h1-font-size);
  color: var(--title-color);

}

.section__subtitle {
  display: block;
  font-size: var(--small-font-size);
  margin-bottom: 3rem;
}

.section__title,
.section__subtitle {
  text-align: center;
}

/* ====== LAYOUT ====== */
.container {
  max-width: 968px;
  margin-left: auto;
  margin-right: auto;
}

.grid {
  display: grid;
  gap: 1.5rem;
}

/* ====== BREAKPOINT ====== */
.button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: var(--title-color-dark);
    color: var(--container-color);
    padding: 1rem 1.25rem;
    border-radius: 1rem;
    font-weight: var(--font-medium);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: 1.5s;
}

.button:hover {
  background: linear-gradient(to right, #6698FF, #83B2FF);
  color: #fff;
  opacity: 0.9;
  box-shadow: 0 2px 4px rgba(79, 76, 76, 0.3);

}

.button__icon {
  margin-left: var(--mb-0-5);
}

.button--flex {
  max-width: 250px;
}

.details__title {
  cursor: default;
  font-size: var(--medium-font-size);
  color: #83B2FF;
  transition: color 0.3s;
}

.details__title:hover {
  color: var(--title-color);
}

/* ====== BREAKPOINT ====== */
/* For large devices */
@media screen and (max-width: 992px) {
  .container {
    margin-left: var(--mb-1-5);
    margin-right: var(--mb-1-5);
  }

  .button {
    padding: 1rem 2.5rem;
  }

  .button__icon {
    width: 22px;
    height: 22px;
  }
}

/* For medium devices */
@media screen and (max-width: 768px) {
  body {
    margin: 0 0 var(--header-height) 0;
  }

  .section {
    padding: 2rem 0 3rem;
  }

  .section__subtitle {
    margin-bottom: var(--mb-3);
    padding: 0 1rem;
  }
  
}

@media screen and (max-width: 576px) {
  
}

/* For small devices */
@media screen and (max-width: 350px) {

  :root {
    --big--font-size: 2.25rem;
  }

  .container {
    margin-left: var(--mb-1);
    margin-right: var(--mb-1);
  }
  

}
