.header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--z-fixed);
    background-color: var(--body-color);
}

.nav {
    height: calc(var(--header-height) + 1.5rem);
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 1rem;
}

.nav__logo,
.nav__toggle {
    color: var(--title-color);
    font-weight: var(--font-semi-bold);
}

.nav__list {
    display: flex;
    column-gap: 2rem;  
}

.nav__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: var(--small-font-size);
    color: var(--title-color);
    font-weight: var(--font-mediun);
    transition: .3s;
}

.nav__link--contact {
  text-decoration: overline;
  text-decoration-color: #83B2FF;
  text-decoration-style: wavy;
}

.nav__icon, 
.nav__close,
.nav__toggle {
    display: none;
}

/* Active Link */

.nav__link:hover {
    color: #83B2FF;
    font-size: 0.975rem;
    transition: .3s;
}

/* Change Header */
.scroll-header {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* ====== BREAKPOINT ====== */

/* For medium devices */
@media screen and (max-width: 768px) {
  .header {
    top: initial;
    bottom: 0;
  }

  .nav {
    height: var(--header-height);
  }

  .nav__menu {
    position: fixed;
    bottom: -100%;
    left: 0;
    width: 100%;
    background-color: var(--body-color);
    padding: 2rem 1.5rem 4rem;
    box-shadow: 0 -1px 4px rgba(0,0,0, 0.15);
    border-radius: 1.5rem 1.5rem 0 0;
    transform: .3s;
  }

  .show-menu {
    bottom: 0;
  }

  .nav__link {
    display: flex;
    flex-direction: row; /* Torna o layout horizontal */
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem; /* Espaço entre o ícone e o texto */
    font-size: var(--small-font-size);
  }



  .nav__list {
    display: flex;
    flex-direction: column; /* Deixe o layout como coluna */
    align-items: center;
    gap: 1rem; /* Ajuste o espaçamento entre os itens */
  }

  .nav__icon {
    font-size: 1.2rem;
    color: var(--title-color);
  }

  .nav__close {
    position: absolute;
    right: 1.3rem;
    bottom: .5rem;
    font-size: 1.5rem;
    cursor: pointer;
    color: var(--title-color);
  }

  .nav__close:hover {
    color: var(--title-color-dark);
  }

  .nav__toggle {
    font-size: 1.5rem;
    cursor: pointer;
    animation-name: iconColorAnimation;
    animation-duration: 3s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }

  @keyframes iconColorAnimation {
    0% { color: #83B2FF; }
    50% { color: #9db3d5; }
    100% { color: #83B2FF; }
  }

  

  .nav__icon,
  .nav__close,
  .nav__toggle {
    display: block;
  }
}

/* For small devices */
@media screen and (max-width: 350px) {
    .nav__menu {
        padding: 2rem 0.25rem 4rem;
    }

    .nav__list {
        column-gap: 0;
    }
  
}
