.footer {
    background-color: var(--container-color);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.footer__container {
    padding: 2rem 0 2rem;
}

.footer__title,
.footer__link {
    color: var(--title-color);
}

.footer__title {
    text-align: center;
    margin-bottom: var(--mb-2);
}

.footer__link:hover {
    color: #83B2FF;
    font-size: 0.975rem;
    transition: .3s;
}

.footer__list {
    display: flex;
    justify-content: center;
    column-gap: 1.5rem;
    margin-bottom: var(--mb-2);
}

.footer__social {
    display: flex;
    justify-content: center;
    column-gap: 1.25rem;
}

.footer__social-icon {
    background-color: #83B2FF;
    color: white;
    font-display: 1.25rem;
    padding: .4rem .5rem;
    border-radius: 0.5rem;
    display: inline-flex;
    animation: pulse 1s ease-in-out infinite;
}

.footer__social-icon:hover {
    background-color: #65A1FF;
    color: #FFFFFF;
}

.footer__copy {
    display: block;
    margin-top: 4.5rem;
    color: var(--title-color);
    text-align: center;
    font-size: var(--smaller-font-size);
}

/* ====== BREAKPOINT ====== */
/* For large devices */
@media screen and (max-width: 992px) {
    .footer__social-icon {
        padding: 0.25rem;
        border-radius: .25rem;
        font-size: 1rem;
    }
  }
  